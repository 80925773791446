<template>
  <module-container title="更多内容">
    <div class="link-group">
      <a href="//www.gamekee.com/u" target="_blank">游戏学院</a>
      <a href="//www.gamekee.com/wiki" target="_blank">wiki指南</a>
      <a href="//space.bilibili.com/334551777" target="_blank">GameKee游戏姬</a>
      <a href="//www.gamekee.com/76934.html" target="_blank">申请wiki</a>
      <!-- 小程序 -->
      <el-popover
        popper-class="miniapp-popover"
        :visible-arrow="false"
        trigger="hover"
        placement="bottom-start"
        width="122"
      >
        <a href="javascript:void(0)" slot="reference">
          小程序
        </a>
        <div class="container">
          <div class="title">
            <div>扫码关注</div>
            <div>GameKee小程序</div>
          </div>
          <div class="tips">
            <div>站内消息随时查看</div>
          </div>
          <div class="qrcode">
            <QRCode />
          </div>
        </div>
      </el-popover>
      <!-- 服务号 -->
      <el-popover
        popper-class="miniapp-popover"
        :visible-arrow="false"
        trigger="hover"
        placement="bottom-start"
        width="122"
      >
        <a href="javascript:void(0)" slot="reference">
          服务号
        </a>
        <div class="container">
          <div class="title">
            <div>扫码关注</div>
            <div>GameKee服务号</div>
          </div>
          <div class="tips">
            <div>互动消息实时推送</div>
          </div>
          <div class="qrcode">
            <img src="@/assets/images/service_qrcode.png" alt="服务号二维码" />
          </div>
        </div>
      </el-popover>
    </div>
  </module-container>
</template>
<script>
import ModuleContainer from "./moduleContainer.vue";

export default {
  name: "moreContent",
  inject: ["getWxaQrcode", "getServiceQrcodeSrc"],
  components: {
    ModuleContainer,

    // eslint-disable-next-line vue/no-unused-components
    QRCode: {
      inject: ["getWxaQrcode", "getServiceQrcodeSrc"],
      data() {
        return { src: "" };
      },
      async mounted() {
        this.src = await this.getWxaQrcode();
      },
      render(h) {
        return h("img", {
          attrs: {
            src: this.src
          }
        });
      }
    }
  },
  data() {
    return {};
  }
};
</script>

<style lang="less">
.miniapp-popover {
  width: 188px !important;
  height: 264px !important;
  border-radius: 12px !important;
  padding: 0 !important;
  border: none !important;
  background: url("../../../assets/images/miniapp-msg-tips-bg.png") no-repeat !important;
  background-size: 188px 264px !important;
  box-shadow: none !important;

  .container {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 65px 20px 18px;
    .title {
      width: 100%;
      padding-right: 8px;
      display: flex;
      flex-direction: column;
      gap: 8px;
      font-size: 17px;
      font-weight: 900;
      color: #435f6a;
      text-align: right;
      line-height: 17px;
      margin-bottom: 12px;
    }
    .tips {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 6px;
      font-weight: 400;
      font-size: 12px;
      color: #666666;
      margin-bottom: 10px;
      &::before,
      &::after {
        content: "";
        display: block;
        width: 20px;
        height: 1px;
        background: #dfdfdf;
      }
    }
    .qrcode {
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 100px;
        height: 100px;
      }
    }
  }
}
</style>
<style scoped lang="less">
.link-group {
  display: flex;
  a {
    width: 150px;
    height: 40px;
    border-radius: 4px;
    border: 1px solid #cecece;
    font-size: 14px;
    color: #666666;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 16px;
  }
}
</style>
